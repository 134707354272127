@keyframes rouleauWidth {
  0% {
    width: 0.5vw;
  }

  100% {
    width: 35vw;
  }
}
@keyframes rouleauWidthMobile {
  0% {
    height: 0vh;
  }

  100% {
    height: 50vh;
  }
}