header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;

  #navmobile {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 3rem;
    height: 3.25rem;
    background-color: #f38c59;    
    box-shadow: .2rem .2rem 5px #b5643c;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    z-index: 11;
    @media (orientation: landscape) {
      display: none;
    }

    span {
      height: 0.3rem;
      width: 70%;
      display: block;
      background-color: #FFF;
      transition: all .5s;
    }

    &.on {
      top: 0.5rem;
      background-color: #FFF;
      box-shadow: 0rem 0rem 0px #FFF;

      span {
        background-color: #f38c59;
        position: absolute;
        transform: rotate(45deg);

        &:nth-child(1) {
          transform: rotate(135deg);
        }
      }
    }
  }

  nav {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    margin: 0;
    @media (orientation: portrait) {
      top: 5rem;
      display: none;
      top: 0;
      left: 0;
      right: initial;
      width: 100%;
      height: 100vh;
      z-index: 10;
      background-color: #FFF;
      padding-top: 4rem;
      border-left: 0.5rem solid #f38c59;
      border-right: 0.5rem solid #f38c59;
      box-sizing: border-box;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-family: 'Goudy Stout';
        cursor: pointer;
        background-color: #FFF;
        position: relative;
        border-left: 0.5rem solid #f38c59;
        border-right: 0.5rem solid #f38c59;
        @media (orientation: portrait) {
          border: 0;
        }

        &::before {
          content: " ";
          position: absolute;
          z-index: -1;
          left: -1rem;
          top: 50%;
          transform: translateY(-50%);
          width: calc(100% + 2rem);
          background-color: #000;
          height: .7rem;
          @media (orientation: portrait) {
            display: none;
          }
        }

        &.hover {
          // margin-bottom: 3rem;
          ul {
            height: auto;
            overflow: visible;
            padding-top: calc(2.5rem + .5rem);

            &::before {
              content: " ";
              position: absolute;
              top: 1.0rem;
              left: 0;
              width: 100%;
              height: 1rem;
              background-color: #000;
              filter: blur(.5rem);
            }
          }
        }
      }

      span {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        background-color: #FFF;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
      }

      ul {
        position: absolute;
        z-index: 0;
        top: 0rem;
        left: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding-top: 0rem;
        background-color: #FFF;
        overflow: hidden;
        height: 100%;
        box-sizing: border-box;
        transition: all .5s;
        @media (orientation: portrait) {
          position: relative;
          width: 100%;
        }

        li {
          border: none;
          padding-bottom: 0.0rem;
          text-align: center;
          @media (orientation: portrait) {
            text-align: left;
          }

          &::before {
            display: none;
          }

          img {
            width: 90%;
            @media (orientation: portrait) {
              width: 90%;
              object-fit: contain;
              object-position: left 10% top 50%;
              height: 50px;
              padding: 1rem 0 1rem;
            }
          }
        }
      }
    }
  }
}