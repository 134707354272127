.home {

    .logo {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        width: 7rem;
        height: 10rem;
        background: url(../../img/logo.png) center center no-repeat;
        background-size: contain;
        @media (orientation: portrait) {
            width: 3.5rem;
            height: 5rem;
        }
        
        &::after {
            content: " ";
            position: absolute;
            z-index: -1;
            background-color: #000;
            top: .5rem;
            filter:blur(1rem);
            left: -.5rem;
            width: 100%;
            height: 100%;
        }
    }

    h1 {
        background-color: #f38c59;
        position: absolute;
        margin: 0;
        padding: 1.25rem 1.7rem 1.25rem;
        font-size: 2.5rem;
        top: 1rem;
        left: 3rem;
        color: #FFF; 
        letter-spacing: -0.4rem;
        text-shadow: 2px 2px #000, -2px 2px #000, 2px -2px #000, -2px -2px #000;
        box-shadow: 0.2rem 0.2rem 5px #b5643c;

        @media (orientation: portrait) {
            left: initial;
            top: initial;
            margin: 1rem 0 0 2rem;
            padding: 1.00rem 0.8rem 1.00rem;
            font-size: 0.95rem;
            max-width: 90vw;
            width: auto;
            text-shadow: 1px 1px 5px #000;
            letter-spacing: -0.1rem;
            display: inline-block;
            float: left;
            box-sizing: border-box;
            position: relative;
        }

        &::before {
            content: " ";
            position: absolute;
            top: -.45rem;
            left: -1.1rem;
            width: .75rem;
            height: calc(100% + .9rem);
            background-color: #000;
        }
        &::after {
            content: " ";
            position: absolute;
            top: -.05rem;
            left: -1.75rem;
            width: 2rem;
            height: calc(100% + .1rem);
            background-color: #f38c59;
            box-shadow: 1rem 0 10px #b5643c;
            @media (orientation: portrait) {
                box-shadow: .5rem 0 10px #b5643c;
            }
        }
    }

    .rouleau {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (orientation: portrait) {
            margin-top: 2rem;
        }

        .contentRouleau {
            position: relative;
            box-sizing: border-box;
            background-color: #FFF;
            text-align: center;
            font-size: 1.25rem;
            width: 0.5vw;
            overflow: hidden;
            transition: 1s all;

            &.on {
                width: 35vw;
                @media (orientation: portrait) {
                }
            }

            @media (orientation: portrait) {
                height: 0vh;
                width: 85vw;

                &.on {
                    width: 85vw;
                    height: 55vh;
                }
            }

            &::before {
                content: " ";
                position: absolute;
                width: calc(35vw - 1rem);
                height: calc(100% - 1rem);
                border: 2px solid #f38c59;
                top: 50%;
                left: 0.5rem;
                box-sizing: border-box;
                transform: translateY(-50%);
                @media (orientation: portrait) {
                    width: calc(100% - 1rem);
                }
            }
            &::after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(167,167,167,1) 0%, rgba(167,167,167,0) 2%, rgba(167,167,167,0) 98%, rgba(167,167,167,1) 100%);
                filter: blur(.2rem);
            }

            p {
                padding: 1rem 3rem 1rem;
                width: calc(35vw - 6rem);
                @media (orientation: portrait) {
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 1.1rem;
                }
            }

            h2 {

            }
            img {
                height: 4rem;
                width: 100%;
                object-fit: contain;
                object-position: top 50% left 50%;
            }

            .game {
                display: none;
                padding: 1rem 1.5rem;
                box-sizing: border-box;
                text-align: center;

                p {
                    padding: 0 1rem;
                    min-width: 100%;
                    box-sizing: border-box;
                }
            }
        }

        .rouleaubord {
            width: 2rem;
            background-color: #000;
            position: absolute;
            height: calc(100% + 2rem);
            top: -1rem;
            left: -3.5rem;
            z-index: 10;
            @media (orientation: portrait) {
                left: 50%;
                height: 95vw;
                top: -2.25rem;
                width: calc(100% + 1.75rem);
                height: 1.5rem;
                transform: translateX(-50%);
            }

            &::before {
                content: " ";
                position: absolute;
                top: .5rem;
                left: -1.5rem;
                width: calc(100% + 3rem);
                height: calc(100% - 1rem);
                background: repeating-linear-gradient(
                0deg,
                #FFF,
                #FFF 1rem,
                #f38c59 1rem,
                #f38c59 2.5rem
                );
                @media (orientation: portrait) {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: calc(100% + 2rem);
                    width: calc(100% - 1rem);
                    background: repeating-linear-gradient(
                    90deg,
                    #FFF,
                    #FFF 1rem,
                    #f38c59 1rem,
                    #f38c59 2.5rem
                    );
                }
            }

            &-droite {
                left: initial;
                right: -3.5rem;
                @media (orientation: portrait) {
                    right: initial;
                    left: 50%;
                    display: none;
                }
            }
        }
    }
}