.btn {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;
  color: #fff;
  text-align: center;

  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  margin: 1rem 0;
  z-index: 10;
  letter-spacing: 0.2rem;

  &-redo {
    background: url(../../img/cta/cta_back_blanc.svg) center center no-repeat;
    background-size: 2rem auto;
    width: 3rem;
    height: 3rem;
    margin: 0;
    @media (min-width: ($bp-mobile+1)) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      right: 0;

      &:hover {
        right: 0.25rem;
      }
    }
  }
  &-bot {
    background: url(../../img/cta/arrow_bot_gold.svg) center center no-repeat;
    background-size: 1.6rem auto;
    width: 3rem;
    height: 3rem;
    margin: 0;
    @media (min-width: ($bp-mobile+1)) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      right: 0;

      &:hover {
        right: 0.25rem;
      }
    }
  }
  &-next,
  &-feed,
  &-go {
    background: url(../../img/cta/arrow_next.svg) center center no-repeat;
    background-size: 1.6rem auto;
    width: 3rem;
    height: 3rem;
    margin: 0;
    @media (min-width: ($bp-mobile+1)) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      right: 0;

      &:hover {
        right: 0.25rem;
      }
    }

    span {
      color: #fff;
      text-align: center;

      font-size: 1.35rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      // display: inline-block;
    }

    &::after {
      display: none;
    }

    @media (max-width: $bp-mobile) and (orientation: portrait) {
      &.btn-noirmobile {
        background: url(../../img/cta/arrow_next.svg) center center no-repeat;
        background-size: 2rem auto;
      }
    }
  }

  &-next.btn-next-noir {
    background: url(../../img/cta/arrow_next_noir.svg) center center no-repeat;
    background-size: 1.6rem auto;
  }
  &-feed.btn-next-noir {
    background: url(../../img/cta/arrow_next_noir.svg) center center no-repeat;
    background-size: 1.6rem auto;
  }
  &-next.btn-next-gold {
    background: url(../../img/cta/arrow_next_gold.svg) center center no-repeat;
    background-size: 1.6rem auto;
  }
  &-download {
    background: url(../../img/bg/bg_reponse.jpg) center center no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.85rem 1.5rem;
    text-align: center;
    font-family: Interstate;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    position: relative;

    &::after {
      content: " ";
      border: 1px solid $gold;
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.off-mobile {
    @media (max-width: ($bp-mobile+1)) {
      display: none;
    }
  }

  &.off-desktop {
    @media (min-width: ($bp-mobile+1)) {
      display: none;
    }
  }

  &.btn-enter,
  &-submit {
    background-color: #fff;
    color: $yellow;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    letter-spacing: 0;
    gap: 0.5rem;
    width: auto;
    height: auto;
  }

  //------------------------------------------
  &:hover {
    &.btn-enter:not(.disabled) {
      &::after {
        margin-left: 15px;
      }
    }
  }

  &-disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
