html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: url(../../img/back.jpg) center center no-repeat #FFF;
  background-size: cover;
  font-family: Arial;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Goudy Stout';
  font-weight: 400;
  font-style: normal;
}

b {
  font-family: 'Goudy Stout';
}